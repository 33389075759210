import dynamic from 'next/dynamic';
import React from 'react';
import styled from 'styled-components';

const AdsSlot = dynamic(() => import('./AdsSlot'), {
    ssr: false
});

interface Props {
    id?: string;
}

interface ContainerProps {
    'data-margin'?: string;
}
const Container = styled.div<ContainerProps>`
    width: 950px;
    height: 200px;
    background: #f9f9f9;
    @media (max-width: 992px) {
        width: 300px;
        height: 100px;
    }
    margin: auto;
`;

const Wideboard = ({ id }: Props): JSX.Element => {
    if (!id) return null;
    return (
        <Container id={`container-div-gpt-ad-${id}`} key={id}>
            <AdsSlot slotId={id} />
        </Container>
    );
};
export default Wideboard;
